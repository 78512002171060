import React, { Suspense } from "react";
import './css/app.css';
import { Route } from "react-router";
import Loading from "./components/Loading";
const Weather = React.lazy(() => import("./components/Weather"));

function App() {
  return (
    <div className="app">
      <Suspense fallback={Loading}>
        <Route exact path="/" component={Weather} />
        <Route path="/q/:name" component={Weather} />
      </Suspense>
    </div>
  );
}

export default App;
