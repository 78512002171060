import React from 'react';
import '../css/loading.css';

const Loading = () => {
    return (
        <div className="loading">
            <div className="loading-box">
                <span></span>
            </div>
        </div>
    )
}

export default Loading;